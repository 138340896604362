<!--  -->
<template>
  <div>
    <pHeader />
    <div class="content">
      <div class="inner">
        <div class="part">
          <p class="tit">选择授权</p>
          <div class="list">
            <div class="line">
              <div class="lable l">产品名称:</div>
              <div class="value t1">{{frame.name}}</div>
            </div>
            <!--            <div class="line">-->
            <!--              <div class="lable l">产品类型:</div>-->
            <!--              <div class="value t2 layout-flex">-->
            <!--                <div-->
            <!--                  :class="['item',{'active':current==index}]"-->
            <!--                  v-for="(item,index) in products"-->
            <!--                  :key="index"-->
            <!--                  @click="current=index"-->
            <!--                >-->
            <!--                  <p class="name">{{item.name}}</p>-->
            <!--                  <p class="price">¥ {{item.price}}</p>-->
            <!--                  <img src="@/assets/img/jiaobiao.png" alt v-if="index==current" />-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="line">
              <div class="lable l">授权须知:</div>
              <div class="value t3" @click="dialogVisible=true">点击我阅读</div>
            </div>
          </div>
        </div>
        <div class="part">
          <p class="tit">购买方式/填写信息</p>
          <div class="list">
            <div class="line">
              <div class="lable">支付方式:</div>
              <div class="value layout-flex t4">
                <div class="item">
                  <img src="@/assets/img/wxicon.png" />
                  <el-radio v-model="radio" label="微信支付"></el-radio>
                </div>
                <!--                <div class="item">-->
                <!--                  <img src="@/assets/img/zfbicon.png" />-->
                <!--                  <el-radio v-model="radio" label="支付宝支付"></el-radio>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="line">
              <div class="lable">授权手机号:</div>
              <div class="value t5">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                  <el-form-item prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="line">
              <div class="lable">购买说明:</div>
              <div class="value t6">
                <!--                下单后会将授权资料发送至-->
                <!--                <span>QQ邮箱</span> ，注意查收-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calculation">
      <div class="inner layout-flex-all">
        <div class="l">{{frame.name}}</div>
        <div class="r">
          支付金额 :
          <span>￥{{frame.price}}</span>
          <div v-if="!token" class="btn" @click="login('ruleForm')">确认订单-登录</div>
          <div v-else class="btn" @click="save('ruleForm')">确认订单-购买</div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog
      title="企业版授权说明"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      custom-class="dialog"
    >
      <div class="inner">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="权益说明" name="first">
            <el-alert title="成功提示的文案" type="success"></el-alert>
            <el-alert title="消息提示的文案" type="info"></el-alert>
            <el-alert title="警告提示的文案" type="warning"></el-alert>
            <el-alert title="错误提示的文案" type="error"></el-alert>
          </el-tab-pane>
          <el-tab-pane label="问题解答" name="second">
            <el-alert title="成功提示的文案" type="success"></el-alert>
            <el-alert title="消息提示的文案" type="info"></el-alert>
            <el-alert title="警告提示的文案" type="warning"></el-alert>
            <el-alert title="错误提示的文案" type="error"></el-alert>
          </el-tab-pane>
          <el-tab-pane label="软著证书" name="third">
            <!--            <img src="@/assets/img/rz.png" alt class="rz" />-->
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <!-- 弹出框 -->
    <el-dialog
      title="支付"
      :visible.sync="payDialogVisible"
      width="30%"
      :before-close="handleClose"
      custom-class="dialog"
    >
      <div class="dialog-inner">
        <p class="tit">
          <img src="@/assets/img/wxicon.png" alt />
          请使用微信扫一扫
          <br />完成支付
        </p>
        <p class="price">￥{{orderInfo.orderPrice}}</p>
        <img :src="qrcodeUrl" class="qrcode" />
        <div class="time">
          <p class="t">请在5分钟内扫码付款</p>
          <p class="tt">
            <span>{{showTime[0]}}</span>:
            <span>{{showTime[2]}}</span>
          </p>
        </div>
        <ul class="infoList">
          <li>产品名称: {{frame.name}}</li>
          <li>订单单号: {{orderSn}}</li>
          <li>支付方式: 微信</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFrameInfo, saveOrder, loginByDomain, payMoneyWeChat, orderDetail } from "@/api";
import { base64encodeUrlSafe } from "@/common/index.js"
import qrcode from "qrcode";
export default {
  data () {
    return {
      showTime: [], //倒计时
      serialNum: '',//流水号
      frame: {},
      qrcodeUrl: '',//二维码。
      orderSn: '',//订单编号
      orderInfo: {}, //订单信息
      timer: '', //轮询的。
      counting: '',//倒计时
      activeName: 'first',
      dialogVisible: false,
      payDialogVisible: false,
      current: 0,
      token: localStorage.getItem('token'),//判断用户是否登录的。
      userInfo: localStorage.getItem('userInfo'),//用户的信息
      products: [{
        name: "企业版",
        price: "2000"
      },
      {
        name: "旗舰版",
        price: "10  00"
      }],
      radio: "微信支付",
      ruleForm: {
        phone: ""
      },
      rules: {
        phone: [
          { required: true, message: '请输入授权手机号', trigger: 'blur' },
          { pattern: /^1[345678]\d{9}$/, message: '请输入合法的手机号', trigger: 'blur' }
        ]
      },
    }
  },
  components: {},

  computed: {},
  created () {
    window.clearInterval(this.timer);
    getFrameInfo({ id: this.$route.query.id }).then(res => {
      console.log(res)
      this.frame = res.data
    })
    console.log(localStorage)
    this.token = localStorage.getItem('token')
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log('this.token', this.token)
    console.log('this.serialNum', this.$route.query.serialNum)
    this.serialNum = this.$route.query.serialNum
    if (this.serialNum) { //登录完返回的这个页面。拿着流水号去登录。
      loginByDomain({ serialNum: this.$route.query.serialNum }).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.serialNum = '' //置空。。免得刷新多次请求。
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo))
          localStorage.setItem("fortressRefreshToken", res.data.refreshToken)
          localStorage.setItem("fortressExpiredTime", res.data.expiredTime)
            //刷新一下，显示头像等信息
          let fullPath = this.$route.fullPath
          //不论有没有参数。去除 serialNum 参数。。避免跳转回来时两个serialNum参数会报错
          fullPath = fullPath.replaceAll(/[?&]serialNum=[^&]*&?/g, '&')
          //如果serialNum 是最后一个参数，会出现 最后一个符号是& 如果有 切掉
          fullPath = fullPath.lastIndexOf('&') == (fullPath.length - 1) ? fullPath.substring(0, fullPath.length - 1) : fullPath;
          window.location.href = "http://frame.icuapi.com" + fullPath;
          this.token = res.data.token
        }
      })
    }
  },
  methods: {
    handleClose () {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
      this.dialogVisible = false
      this.payDialogVisible = false
      window.clearInterval(this.counting)
      console.log('handleClose')
    },
    handleClick () {
      console.log('handleClick')
    },
    login (name) {
      //跳转到console去登录。
      //判断是否登录。未登录先去登录。
      this.$refs[name].validate((valid) => {
        if (valid) {
          let fullPath = this.$route.fullPath
          //不论有没有参数。去除 serialNum 参数。。避免跳转回来时两个serialNum参数会报错
          fullPath = fullPath.replaceAll(/[?&]serialNum=[^&]*&?/g, '&')
          //如果serialNum 是最后一个参数，会出现 最后一个符号是& 如果有 切掉
          fullPath = fullPath.lastIndexOf('&') == (fullPath.length - 1) ? fullPath.substring(0, fullPath.length - 1) : fullPath;
          console.log('要返回的路径', fullPath)
          //要返回的页面
          const originUrl = base64encodeUrlSafe("http://frame.icuapi.com" + fullPath)
          console.log(originUrl)
          window.location.href = "https://console.icuapi.com/" + (originUrl ? "?originUrl=" + originUrl : "");
        }
      })

    },
    //保存订单 -- 拉起支付。
    save (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          //生成订单  //TODO 需要获取用户ID。。。 this.user.id
          const order = {
            memberId: this.userInfo.id,
            shopId: this.frame.id,
            type: 2,//2 框架
          }
          saveOrder(order).then(res => { //保存订单
            if (res.code == 0) {
              console.log(res, '订单信息');
              this.orderSn = res.data.orderSn
              this.orderInfo = res.data
              this.topay()
            }
          })
        }
      })

    },
    //拉起支付
    topay () {
      const params = {
        amount: this.orderInfo.price,
        orderName: "框架购买",
        ordersn: this.orderSn,
        payTypeEnum: "WXPAY_NATIVE",
        attach: "CastleFrame会员中心",
      }
      //拉起支付
      payMoneyWeChat(params).then(e => {
        // 微信支付
        qrcode.toDataURL(e.data.codeUrl) //生成支付的二维码。
          .then(url => {
            this.qrcodeUrl = url;
            //5分钟倒计时
            this.showTime = ['05', ':', '00']
            this.timerHandle(5 * 60 - 1)
            this.payDialogVisible = true;

            //触发轮询的监听。监听是否已成功支付。
            this.polling()
          })
          .catch(err => {
            console.error(err)
          })
      });

    },
    countDown (second) {
      const s = second % 60;
      const m = Math.floor(second / 60);
      return `${`00${m}`.slice(-2)} : ${`00${s}`.slice(-2)}`;
    },
    timerHandle (time) {
      this.counting = setInterval(() => {
        this.showTime = this.countDown(time--).split(' ');
        if (time < 0) {
          this.payDialogVisible = false
          clearInterval(this.counting);
        }
      }, 1000);
    },
    polling () {
      console.log('进入轮询')
      const order = this.orderInfo;
      this.timer = window.setInterval(() => {
        //轮询的方法
        orderDetail({ orderSn: order.orderSn }).then(res => {
          if (res.status == 2) { //如果已支付。
            window.clearInterval(this.timer);
            this.layer.msg('支付成功', { icon: 1 });
            // 跳转去支付成功页
            setTimeout(() => {
              this.payDialogVisible = false
            }, 1000)

          }
        });
      }, 5000);
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  padding-top: 3rem;
  .inner {
    padding: 2rem;
    max-width: 120rem;
    margin: 0 auto;
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 0.2rem;
    .part {
      margin-bottom: 1.5rem;
      .tit {
        margin-bottom: 20px;
        color: #545454;
        font-size: 16px;
        font-weight: 700;
      }
      .list {
        .line {
          padding: 1.5rem 0;
          display: flex;
          .lable {
            margin-right: 1rem;
            font-size: 14px;
            color: #606266;
            line-height: 1;
          }
          .value {
            line-height: 1;
          }
          .l {
            padding-top: 0.5rem;
          }
          .t1 {
            font-size: 18px;
            font-weight: 600;
            color: #000;
          }
          .t2 {
            .item {
              height: 84px;
              min-width: 182px;
              padding-left: 18px;
              padding-right: 26px;
              background: #fff;
              border: 2px solid #f1f4fb;
              -webkit-box-shadow: 0 4px 10px 0 rgb(135 142 154 / 14%);
              box-shadow: 0 4px 10px 0 rgb(135 142 154 / 14%);
              border-radius: 2px;
              margin-right: 11px;
              margin-bottom: 10px;
              cursor: pointer;

              .name {
                color: #545454;
                font-size: 14px;
                font-weight: 400;
                margin-top: 16px;
                margin-bottom: 5px;
              }
              .price {
                color: #3369ff;
                font-size: 14px;
                font-weight: 700;
              }
            }
            .active {
              color: #3369ff;
              border: 2px solid #3369ff;
              position: relative;
              overflow: hidden;
              .name {
                color: #3369ff;
              }
              img {
                position: absolute;
                right: -1px;
                bottom: -1px;
                z-index: 999;
              }
            }
          }
          .t3 {
            display: inline-block;
            font-size: 22px;
            color: rgb(64, 158, 255);
            cursor: pointer;
          }
          .t4 {
            .item {
              margin-right: 1rem;
              display: flex;
              align-items: flex-start;
              img {
                margin: -1rem 0.5rem 0 0;
                width: 4rem;
                height: 4rem;
              }
            }
          }
          .t5 {
            &::v-deep .el-form {
              .el-input__inner {
                border-radius: 0.4rem;
              }
            }
          }
          .t6 {
            font-size: 14px;
            span {
              color: #3369ff;
            }
          }
        }
      }
    }
  }
}
.calculation {
  width: 100%;
  height: 6rem;
  line-height: 6rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 -1px 0 0 hsl(0deg 0% 71% / 18%);
  .inner {
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 120rem;
    .l {
      color: #545454;
      font-weight: 700;
      font-size: 14px;
      margin-left: 20 px;
    }
    .r {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #545454;
      font-weight: 700;
      span {
        margin-left: 9px;
        font-size: 22px;
        color: #3369ff;
        font-weight: 700;
      }
      .btn {
        margin-left: 2rem;
        width: 135px;
        height: 44px;
        line-height: 44px;
        background: #3369ff;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        letter-spacing: 1px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: none;
        border-radius: 24px;
      }
    }
  }
}
.dialog {
  .inner {
    .rz {
      width: 100%;
    }
  }
  .dialog-inner {
    padding: 0 3rem;
    text-align: center;
    .tit {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 1.5rem;
        width: 6rem;
        height: 6rem;
      }
      font-weight: 500;
      vertical-align: middle;
      text-align: left;
      font-size: 16px;
      line-height: 28px;
      color: #5ac33c;
    }
    .price {
      padding: 1.5rem 0;
      color: #000;
      font-size: 42px;
      font-weight: 500;
    }
    .qrcode {
      width: 180px;
    }
    .time {
      padding: 1rem 0 2rem;
      color: #5ac33c;
      .t {
      }
      .tt {
        font-size: 32px;
        text-align: center;
        display: inline-block;
        margin: 0 3px;
        font-weight: bold;
        span {
          letter-spacing: 10px;
        }
      }
    }
    .infoList {
      li {
        padding: 1rem 0;
        text-align: left;
        font-size: 1.4rem;
      }
    }
  }
}
.price {
  color: #000;
  font-size: 42px;
  font-weight: 500;
}
.tit {
  color: #09bb07;
  font-size: 42px;
  font-weight: 500;
}
</style>
